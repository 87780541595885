/* Proxima Outtrip Thin */
@font-face {
  font-family: 'Proxima Outtrip';
  font-style: normal;
  font-weight: 100;
  src: url("../PROXIMA_OUTTRIP_THIN.OTF") format('opentype');

 }
/* Proxima Outtrip Normal */
@font-face {
  font-family: 'Proxima Outtrip';
  font-style: normal;
  font-weight: 400;
  src: url("../PROXIMA_OUTTRIP_FONT.OTF") format('opentype');

 }
  /* Proxima Outtrip Bold */
@font-face {
  font-family: 'Proxima Outtrip';
  font-style: normal;
  font-weight: bold;
  src: url("../PROXIMA_OUTTRIP_BOLD.OTF") format('opentype');

 }
